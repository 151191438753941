@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400..900;1,400..900&display=swap');

.libretto-section{
    position: relative;
    /* display: flex; */
    align-items: center;
    justify-content: flex-start;
    margin-top: 5em;
    /* height: 100vh; */
    padding: 2em;
    flex-direction: column;
    /* overflow: hidden; */ 
}   

.libretto-chapter h1 {
    font-family: "Alegreya", serif;
    font-weight: 800;
    font-size: 2rem;
    align-items: center;
    margin: 0px;
}

.libretto-chapter h2 {
    font-family: "Alegreya", serif;
    font-weight: 500;
    font-size: 2rem;
    align-items: center;
    margin: 0px;
}

.libretto-body {
    font-family: "Alegreya", serif;
    font-weight: 400;
    font-size: 1.3rem;
    margin-top: 1rem;
    /* padding-left: 1rem; */
    /* text-align: center; */
    line-height: 1.5rem;
}

.libretto-rit {
    margin-top: 1em;
    margin-bottom: 1em;
    font-weight: 500;
}
.libretto-rit2 {
    padding-left: 2em;
    white-space: pre-wrap;
    font-weight: 500;
}

.libretto-risposta {
    margin-top: 0.3em;
    margin-bottom: 0.3em;
    font-weight: 600;
    font-style: italic;
}

.cerimony-section{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5em;
    /* height: 100vh; */
    /* padding: 1.5rem; */
    flex-direction: column;
    /* overflow: hidden; */
    
}   

.cerimony-title {
    font-size: 3rem;
    align-items: center;
    margin: 0px;
}

.case {
    font-size: 1.3rem;
    font-weight: 400;
    margin-top: -1rem;
    padding-left: 1rem;
    text-align: center;
    line-height: 2rem;
}

.address{
    font-size: 1.1rem;
    text-align: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 2rem;
    line-height: 2rem;

}

.cerimony {
    font-size: 3rem;
    align-items: center;
    margin-bottom: -10rem;
}

  /* Added This */
.op-class-cerimony{  
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    width: 50%;
    height: 30%;
}

.fadeIn {
    opacity: 1;
    transition: opacity 4s ;
}

@media screen and (max-width: 850px) {

    .cerimony-title {
        font-size: 2rem;
    }

    .case {
        margin-left: 0.3em;
        margin-right: 0.3em;
    }

    .op-class-cerimony{  
        opacity: 1;
        width: 100%;
        height: 100%;
        margin-top: 0em;
    }

  }


  @media screen and (max-width: 500px) {

    .cerimony-section{
        padding: 0.5rem;
        
    }   

    .cerimony {
        font-size: 2rem;
    }

    .op-class-cerimony{  
        margin-top: -3em;
        height: 30em;
    }

  }