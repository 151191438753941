
.markdown-content{
    min-height: 70vh;
    margin-left: 22em;
    margin-right: 22em;
    margin-top: 5em;
    margin-bottom: 5em;

}

.markdown-content h1,
.markdown-content h2,
.markdown-content p,
.markdown-content li {
    text-align: left;
} 


.markdown-content h1 {
    font-family: "Alegreya", serif;
    font-size: 4rem;
    margin-top: 2rem;
    /* display: flex;
    justify-content: center; */
}

.markdown-content h2 {
    font-family: "Alegreya", serif;
    font-size: 2.5rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
    /* display: flex;
    justify-content: center; */
}

.markdown-content h4 {
    font-family: "Alegreya", serif;
    font-size: 1.5rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
    /* display: flex;
    justify-content: center; */
}

.markdown-content p {
    /* font-family: "Alegreya", serif; */
    font-size: 1.3rem;
    margin: 0.6rem;
    /* display: flex;
    justify-content: center; */
}

.markdown-content li {
    font-size: 1.2rem;
    line-height: 1.6;
}

.markdown-content a {
    color: rgb(98, 182, 45);
    font-size: 1rem;
}

.markdown-content img {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    border-radius: 10px;
    margin-top: 1.5em;
    margin-bottom: 1.5em;

}

@media screen and (max-width: 1500px) {
    .markdown-content{
        margin-left: 1em;
        margin-right: 1em;
        margin-top: 1em;
        margin-bottom: 1em;
    }

    .markdown-content h1 {
        font-size: 3rem;
    }

    .markdown-content h2 {
        font-size: 2rem;
    }

}