/* @import url('https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:ital,wght@0,300;1,300&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:ital,wght@0,300;1,300&family=Bellefair&display=swap');

h1, body, html, ::placeholder, input, textarea {
    /* font-family: 'Averia Serif Libre', serif; */
    font-family: 'Bellefair', serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-display: swap;
}

:root {
    --vh: 100vh;
}

.blog-button {
  background-color: var(--a-color);
  color: var(--color-background);
  border-radius: 10px;
  border: 0.5px solid var(--ref-color);
  border-top-color: var(--a-color);
  border-left-color: var(--a-color);
}
.blog-button:hover {
  color: var(--color-text);
  background-color: var(--submit-color);
  border-top-color: var(--submit-color);
  border-left-color: var(--submit-color);
  transition: 200ms ease-in;
}

.intro-section {
  position:static;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--vh);
  /* padding: 1.5rem; */
  overflow: hidden;

}

.intro-section::before, .intro-section::after {
  content: "";
  position: absolute;
  background-image: url('../../assets/poppies.webp');
  top: 0;
  left: 0;
  height: var(--vh);
  width: 100%;
  background-size: 40%;
  background-repeat: repeat-x;
  background-position: bottom;
  transition: opacity 1.5s ease;
  z-index: 1;
}

.intro-section::before {
  opacity: 1;
}


.intro-section::after {
  opacity: 0;
}


  body[data-theme="dark"] .intro-section::before {
      opacity: 0;
  }

  body[data-theme="dark"] .intro-section::after {
      opacity: 1;
  }

.intro-title {
        display: flex;
        flex: 1;
        font-size: 3.5rem;
        text-align: center;
        margin-top: -40vh;
        position: static;
        z-index: 2;
}

.intro-title img {
        flex: 1;
        font-size: 2.5rem;
        text-align: center;
        position: absolute;
        left: 0;
        bottom: 0;
}

.intro-image {
        flex: 1;
}

@media screen and (max-width: 1920px) {
  .intro-section::before, .intro-section::after {
    background-image: url('../../assets/poppies_med_res.webp');
  }
}

@media screen and (max-width: 820px) {
        .intro-section::before, .intro-section::after {
          background-image: url('../../assets/poppies_med_res.webp');
          background-size: 70%;
        }
        .intro-title {
          font-size: 3rem;
          margin-top: -5em;
  }
      }


@media screen and (max-width: 500px) {
        .intro-section::before, .intro-section::after {
          background-image: url('../../assets/poppies_low_res.webp');
          background-size: 100%;
        }

        .intro-title {
          font-size: 2.5rem;
  }
      }