.header-nav{
    display: flex;
    justify-content: right;
    align-items: center; 
    gap: 16px; 
    vertical-align: middle;
    font-size: 1.5rem;
    color: var(--color-text);
    margin-bottom: 0.4em;
    padding-right: 2vw;
    margin-right: 16px;
}

.header-box{
    display: flex;
    align-items: center;
    justify-content: right;
    position: sticky;
    top: 0%;
    height: 100px;
    width: 100%;
    z-index: 1000;
    background-color: var(--color-background);
    transition: background-color 1s ease, color 1s ease;
 
    @media (max-width: 1000px) {
        /* padding: 70px 30px; */
    }
}

@media screen and (max-width: 850px) {


}

@media screen and (max-width: 500px) {


}