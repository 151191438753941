.path:hover {
    stroke-width: 5;
}

.countdown-img img{
    width: 80%;
    margin-top: 3em;
    margin-bottom: 3em;
    border-radius: 15px;
}

.countdown-title {
    font-size: 1.6rem;
    margin-left: 1em;
    margin-right: 1em;
    letter-spacing: 2px;
}

.blog-list {
    flex-grow: 1;
    min-height:100vh;
}

ul {
    display: flex;
    padding-left: 0px !important;
    justify-content: center;
}

.post-container {
    position: relative; /* Needed for positioning hover text */
    display: inline-block; /* Keep each post inline */
    text-align: center; /* Center hover text above */
  }
  
.post-link {
    font-size: 2.5vh; /* Set font size based on viewport height */
    margin: 0.5em;
    display: inline-block; /* Make it behave like an inline block */
    font-weight: 400; /* Set font weight */
    width: 4vh; /* Set the width of the circular element */
    height: 4vh; /* Set height to match width for perfect circle */
    line-height: 4vh; /* Align text vertically within the circle */
    text-align: center; /* Center text horizontally */
    color: var(--selection-color); /* Set text color */
    border-style: solid; /* Add a solid border */
    border-width: 2px; /* Customize border thickness */
    border-color: var(--selection-b-color); /* Use your border color variable */
    background-color: var(--selection-b-color); /* Use your background color variable */
    border-radius: 50%; /* Make it circular */
}

.post-link:hover {
    transform: scale(1.2); /* Enlarge the element slightly on hover */
    background-color: var(--hover-b-color); /* Change background color on hover */
    color: var(--hover-color); /* Change text color on hover */
}

.post-hover-text {
    position: absolute;
    top: -3rem; /* Position text above the circle */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease; /* Smooth fade-in effect */
    font-size: 3vh; /* Increase the font size */
    color: var(--selection-b-color);
    text-align: center;
    white-space: nowrap; /* Ensure the text stays in one line */
}

.post-container:hover .post-hover-text {
    opacity: 1; /* Show the hover text when hovering over the container */
}
  
  


.svg-map {
    display: flex;
    margin-top: 3vh;
    max-width: 90%;
    max-height: 60vh;
    justify-content: center;
}

.svg-map circle {
    fill: var(--circle-color);
}

.svg-map path {
    fill: var(--map-baseline);
    stroke: var(--selection-color);
    stroke-width: 1;
}

.svg-map path:focus {
    outline: none;
    fill: var(--a-color);
    transition: fill 1s ease, color 1s ease;
}

.svg-map path:hover {
    fill: var(--a-color);
    transition: fill 1s ease, color 1s ease;
}

.hover-area {
    z-index: 10;
    display: inline-block; /* Ensures the element respects padding/margin */
    padding: 100px; /* Adjust the value as needed */
}

.svg-map polyline {
    pointer-events: all;
    stroke-width: 3;
    background-clip:content-box;
}

.svg-map polyline:hover {
    stroke-width: 5;
    cursor: pointer;
}

.svg-map circle:hover {
    cursor: pointer;
}

.tooltip {
    display: none;
    position: absolute;
    background-color: white;
    border: 1px solid black;
    padding: 5px;
    z-index: 1000;
}

/* Optional: basic animation to show/hide the tooltip */
.tooltip.fade {
    transition: opacity 0.3s;
    opacity: 0;
}

.tooltip.fade.show {
    opacity: 1;
}

